import DashboardLayout from "@/pages/layouts/DashboardLayout";

let surveyQuestionRoutes = {
  path: '/survey-questions',
  component: DashboardLayout,
  redirect: '/survey-questions/list',
  children: [
    {
      path: 'list',
      name: 'Question List',
      component: () => import(/* webpackChunkName: "survey-questions" */  '@/pages/surveys/SurveyQuestionList'),
      meta: {
        permissionsCodes: ['survey-questions/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Question',
      component: () => import(/* webpackChunkName: "survey-questions" */  '@/pages/surveys/SurveyQuestionForm'),
      meta: {
        permissionsCodes: ['survey-questions/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Question',
      component: () => import(/* webpackChunkName: "survey-questions" */  '@/pages/surveys/SurveyQuestionForm'),
      meta: {
        permissionsCodes: ['survey-questions/update'],
      }
    }
  ]
}

export default surveyQuestionRoutes;
