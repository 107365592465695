import DashboardLayout from "@/pages/layouts/DashboardLayout";

let expertRoutes = {
  path: '/experts',
  component: DashboardLayout,
  redirect: '/experts/list',
  children: [
    {
      path: 'list',
      name: 'Expert List',
      component: () => import(/* webpackChunkName: "experts" */  '@/pages/articles/experts/ExpertList'),
      meta: {
        permissionsCodes: ['experts/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Expert',
      component: () => import(/* webpackChunkName: "experts" */  '@/pages/articles/experts/ExpertForm'),
      meta: {
        permissionsCodes: ['experts/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Expert',
      component: () => import(/* webpackChunkName: "experts" */  '@/pages/articles/experts/ExpertForm'),
      meta: {
        permissionsCodes: ['experts/update'],
      }
    }
  ]
}

export default expertRoutes;
