import DashboardLayout from "@/pages/layouts/DashboardLayout";

let articleTypeRoutes = {
  path: '/article-types',
  component: DashboardLayout,
  redirect: '/article-types/list',
  children: [
    {
      path: 'list',
      name: 'Type List',
      component: () => import(/* webpackChunkName: "article-types" */  '@/pages/articles/articleTypes/ArticleTypeList'),
      meta: {
        permissionsCodes: ['article-types/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Type',
      component: () => import(/* webpackChunkName: "article-types" */  '@/pages/articles/articleTypes/ArticleTypeForm'),
      meta: {
        permissionsCodes: ['article-types/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Type',
      component: () => import(/* webpackChunkName: "article-types" */  '@/pages/articles/articleTypes/ArticleTypeForm'),
      meta: {
        permissionsCodes: ['article-types/update'],
      }
    }
  ]
}

export default articleTypeRoutes;
