import DashboardLayout from "@/pages/layouts/DashboardLayout";

let tagRoutes = {
  path: '/tags',
  component: DashboardLayout,
  redirect: '/tags/list',
  children: [
    {
      path: 'list',
      name: 'Tag List',
      component: () => import(/* webpackChunkName: "tags" */  '@/pages/articles/tags/TagList'),
      meta: {
        permissionsCodes: ['tags/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Tag',
      component: () => import(/* webpackChunkName: "tags" */  '@/pages/articles/tags/TagForm'),
      meta: {
        permissionsCodes: ['tags/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Tag',
      component: () => import(/* webpackChunkName: "tags" */  '@/pages/articles/tags/TagForm'),
      meta: {
        permissionsCodes: ['tags/update'],
      }
    }
  ]
}

export default tagRoutes;
