import DashboardLayout from "@/pages/layouts/DashboardLayout";

let partnerRoutes = {
  path: '/partners',
  component: DashboardLayout,
  redirect: '/partners/list',
  children: [
    {
      path: 'list',
      name: 'Partner List',
      component: () => import(/* webpackChunkName: "partners" */  '@/pages/partners/PartnerList'),
      meta: {
        permissionsCodes: ['partners/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Partner',
      component: () => import(/* webpackChunkName: "partners" */  '@/pages/partners/PartnerForm'),
      meta: {
        permissionsCodes: ['partners/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Partner',
      component: () => import(/* webpackChunkName: "partners" */  '@/pages/partners/PartnerForm'),
      meta: {
        permissionsCodes: ['partners/update'],
      }
    }
  ]
}

export default partnerRoutes;
