<template>
  <div>
    <FileUpload  :name="'video'" :url="getUploadUrl()"
                 @upload="afterUpload"
                 @before-send="beforeSend"
                 :fileLimit="filelimits"
                 accept="video/*" :maxFileSize="maxFileSize">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
    <div v-if="value" class="el-upload-list--picture-card">
      <div class="el-upload-list__item" :style="{width:previewWidth,height:previewHeight}">
        <video controls>
          <source :src="getUrl()+value">
          Your browser does not support the video tag.
        </video>
        <span class="el-upload-list__item-actions">
        <span class="el-upload-list__item-preview" @click="handleVideoCardPreview"><i class="el-icon-zoom-in"></i></span>
        <span class="el-upload-list__item-delete" @click="handleRemove"><i class="el-icon-delete"></i></span></span>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" @close="closeVideoPreview">
      <video id="video-preview" controls width="650" height="300">
        <source :src="dialogVideoUrl">
        Your browser does not support the video tag.
      </video>
    </el-dialog>
  </div>



</template>

<script>

import {Upload, Dialog, Button} from 'element-ui';
import FileUpload from 'primevue/fileupload';


export default {

  name: "PrimeVideoUploader",
  props: {
    elementNum:{
      default:1
    },
    maxFileSize:{
      default:1000000
    },
    buttonTitle: {
      default: "Click To Upload"
    },
    value: {},
    previewWidth: {
      type: String,
      default:"240px"
    },
    previewHeight: {
      type: String,
      default:"auto"
    },
  },

  components: {
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    FileUpload,
  },

  data() {
    return {
      dialogVideoUrl: '',
      filelimits:1,
      disabled: false,
      dialogVisible: false
    };
  },
  mounted() {
    this.filelimits = this.elementNum;
  },
  methods: {
    beforeSend: function (event) {
      this.filelimits++;
      event.xhr.setRequestHeader('Authorization', "Bearer " + this.$store.getters["auth/token"]);
    },
    afterUpload: function (event) {
      let resp = JSON.parse(event.xhr.response);
      this.$emit("input", resp.data.url);
    },
    handleVideoCardPreview() {
      this.dialogVideoUrl = process.env.VUE_APP_SERVER_IMAGE_URL + this.value;
      this.dialogVisible = true;
    },
    handleRemove(x=1000) {
      this.$emit('input', "");
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },

    getUploadUrl(){
        return process.env.VUE_APP_SERVER_IMAGE_URL+'api/files/upload-video';
    },
    closeVideoPreview(){
      var vid = document.getElementById("video-preview");
      vid.pause();
      this.dialogVideoUrl = null;
    }
  },
}
</script>
<style scoped>
.el-upload-list__item {
  margin-top: 10px;
}
.cms-image{
  width: 200px;
  height: 200px;
  object-fit: cover;
}
</style>
