<template>
  <div>
    <FileUpload :name="getName()" :url="getUploadUrl()"
                @upload="afterUpload"
                @before-send="beforeSend"
                :fileLimit="filelimits"
                :multiple="isMultiple()"
                accept="image/*" :maxFileSize="maxFileSize">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
    <div v-if="!isMultiple() && value" class="el-upload-list--picture-card">
      <div class="el-upload-list__item" :style="{width:previewWidth,height:previewHeight}">
        <img alt="" :src="getUrl()+value" class="el-upload-list__item-thumbnail">
        <span class="el-upload-list__item-actions">
        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview"><i
          class="el-icon-zoom-in"></i></span>
        <span class="el-upload-list__item-delete" @click="handleRemove"><i class="el-icon-delete"></i></span></span>
      </div>
    </div>

    <div v-if="isMultiple() && value.length" class="el-upload-list--picture-card">
      <draggable v-model="value" group="gallary" @start="drag=true" @end="moveEventfunction" class="d-flex flex-wrap">
        <div v-for="(image, x) in value" :key="'image_'+x">
          <div class="el-upload-list__item" :style="{width:previewWidth,height:previewHeight}">
            <img alt="" :src="getUrl()+image.image" class="el-upload-list__item-thumbnail cms-image">
            <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handleMultiplePictureCardPreview(image.image)"><i
            class="el-icon-zoom-in"></i></span>
          <span class="el-upload-list__item-delete" @click="handleRemove(x)"><i
            class="el-icon-delete"></i></span>

            <span v-if="withDescription" class="el-upload-list__item-add-text" @click="handleAddText(image.description, x)"><i
            class="el-icon-edit"></i></span>
          </span>
          </div>
        </div>
      </draggable>

    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img style="max-width:100%" :src="dialogImageUrl" alt="" class="cms-image">
    </el-dialog>

    <el-dialog :visible.sync="dialogTextVisible">
      <p>Add image description</p>
      <input v-model="dialogTextDesc" class="description form-control">
      <br>
      <l-button
        class="mt-3"
        type="success"
        @click="saveDescription(dialogTextID, dialogTextDesc)"
        size="m">
        Save
      </l-button>
    </el-dialog>
  </div>


</template>

<script>

import {Button, Dialog, Upload} from 'element-ui';
import FileUpload from 'primevue/fileupload';
import draggable from 'vuedraggable';


export default {

  name: "PrimeUploader",
  props: {
    elementNum: {
      default: 1
    },
    maxFileSize: {
      default: 1000000
    },
    buttonTitle: {
      default: "Click To Upload"
    },
    value: {},
    previewWidth: {
      type: String,
      default: "240px"
    },
    previewHeight: {
      type: String,
      default: "auto"
    },
    withDescription:{
      default:false
    }
  },

  components: {
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    FileUpload,
    draggable,
  },

  data() {
    return {
      images: [],
      filelimits: 1,
      dialogImageUrl: '',
      dialogTextDesc:'',
      disabled: false,
      dialogVisible: false,
      dialogTextVisible: false,
      dialogTextID: ''
    };
  },
  mounted() {
    this.filelimits = this.elementNum;
  },
  methods: {
    moveEventfunction() {
      this.value.map((element, index) => {
        element.sort_order = index
      })
    },
    beforeSend: function (event) {
      this.filelimits++;
      event.xhr.setRequestHeader('Authorization', "Bearer " + this.$store.getters["auth/token"]);
    },
    afterUpload: function (event) {
      let resp = JSON.parse(event.xhr.response);
      if (this.isMultiple()) {
        this.images = this.value;
        this.images = this.images.concat(resp.data.url);
        this.$emit("input", this.images);
      } else {
        this.$emit("input", resp.data.url);
      }
    },
    handlePictureCardPreview() {
      this.dialogImageUrl = process.env.VUE_APP_SERVER_IMAGE_URL + this.value;
      this.dialogVisible = true;
    },
    handleMultiplePictureCardPreview(image) {
      this.dialogImageUrl = process.env.VUE_APP_SERVER_IMAGE_URL + image;
      this.dialogVisible = true;
    },
    handleRemove(x = 1000) {

      if (!this.isMultiple()) {
        this.$emit('input', "");
      } else {
        this.value.splice(x, 1);
        this.$emit("input", this.value);
      }
    },
    handleAddText(description, x= null){
      this.dialogTextID = x;
      this.dialogTextDesc = description;
      this.dialogTextVisible = true;
    },
    saveDescription(id, description){
        this.value[id].description = description
        this.$emit("input", this.value);
        this.dialogTextVisible = false;
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },

    getUploadUrl() {
      if (this.elementNum === 1) {
        return process.env.VUE_APP_SERVER_IMAGE_URL + 'api/files/upload-photo';
      } else if(this.withDescription) {
        return process.env.VUE_APP_SERVER_IMAGE_URL + 'api/files/upload-multiple-photo-with-description';
      }else{
        return process.env.VUE_APP_SERVER_IMAGE_URL + 'api/files/upload-multiple-photo';
      }
    },
    isMultiple() {
      return (this.elementNum !== 1);
    },
    getName() {
      return (this.isMultiple()) ? 'photo[]' : 'photo';
    }
  },
}
</script>
<style scoped>
.el-upload-list__item {
  margin-top: 10px;
}
.cms-image{
  width: 200px;
  height: 200px;
  object-fit: cover;
}
</style>
