import DashboardLayout from "@/pages/layouts/DashboardLayout";

let agendaRoutes = {
  path: '/agenda',
  component: DashboardLayout,
  redirect: '/agenda/list',
  children: [
    {
      path: 'list',
      name: 'Agenda List',
      component: () => import(/* webpackChunkName: "agendas" */  '@/pages/agendas/AgendaList'),
      meta: {
        permissionsCodes: ['agendas/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Agenda',
      component: () => import(/* webpackChunkName: "agendas" */  '@/pages/agendas/AgendaForm'),
      meta: {
        permissionsCodes: ['agendas/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Agenda',
      component: () => import(/* webpackChunkName: "agendas" */  '@/pages/agendas/AgendaForm'),
      meta: {
        permissionsCodes: ['agendas/update'],
      }
    },
    {
      path: 'members/:id',
      name: 'Show Joined Members',
      component: () => import(/* webpackChunkName: "agendas" */  '@/pages/agendas/AgendaMemberList'),
      meta: {
        permissionsCodes: ['agendas/get-members'],
      }
    }
  ]
}

export default agendaRoutes;
