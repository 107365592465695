import DashboardLayout from "@/pages/layouts/DashboardLayout";

let articleRoutes = {
  path: '/articles',
  component: DashboardLayout,
  redirect: '/articles/list',
  children: [
    {
      path: 'list',
      name: 'Article List',
      component: () => import(/* webpackChunkName: "articles" */  '@/pages/articles/articles/ArticleList'),
      meta: {
        permissionsCodes: ['articles/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Article',
      component: () => import(/* webpackChunkName: "articles" */  '@/pages/articles/articles/ArticleForm'),
      meta: {
        permissionsCodes: ['articles/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Article',
      component: () => import(/* webpackChunkName: "articles" */  '@/pages/articles/articles/ArticleForm'),
      meta: {
        permissionsCodes: ['articles/update'],
      }
    }
  ]
}

export default articleRoutes;
