import DashboardLayout from "@/pages/layouts/DashboardLayout";

let surveyRoutes = {
  path: '/surveys',
  component: DashboardLayout,
  redirect: '/surveys/list',
  children: [
    {
      path: 'list',
      name: 'Survey List',
      component:()=>import(/* webpackChunkName: "surveys" */ '@/pages/surveys/surveys/SurveyList'),
      meta: {
        permissionsCodes: ['surveys/index'],
      }
    },
    {
      path: 'show/:id',
      name: 'Survey Details',
      component:()=>import(/* webpackChunkName: "surveys" */ '@/pages/surveys/surveys/SurveyView'),
      meta: {
        permissionsCodes: ['surveys/get'],
      }
    },
  ]
}

export default surveyRoutes;
