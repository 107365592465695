import DashboardLayout from "@/pages/layouts/DashboardLayout";

let congressRoutes = {
  path: '/congresses',
  component: DashboardLayout,
  redirect: '/congresses/list',
  children: [
    {
      path: 'list',
      name: 'Congress List',
      component: () => import(/* webpackChunkName: "congresses" */  '@/pages/congresses/CongressList'),
      meta: {
        permissionsCodes: ['congresses/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Congress',
      component: () => import(/* webpackChunkName: "congresses" */  '@/pages/congresses/CongressForm'),
      meta: {
        permissionsCodes: ['congresses/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Congress',
      component: () => import(/* webpackChunkName: "congresses" */  '@/pages/congresses/CongressForm'),
      meta: {
        permissionsCodes: ['congresses/update'],
      }
    },
    {
      path: 'members/:id',
      name: 'Show Joined Members',
      component: () => import(/* webpackChunkName: "congresses" */  '@/pages/congresses/CongressMemberList'),
      meta: {
        permissionsCodes: ['congresses/get-members'],
      }
    }
  ]
}

export default congressRoutes;
